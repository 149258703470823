import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusQuickAnswers, QuickAnswersEdges } from './quick-answers.interface';

export default (): QuickAnswersEdges[] => {
  const { allDirectusQuickAnswer } = useStaticQuery<AllDirectusQuickAnswers>(graphql`
    query {
      allDirectusQuickAnswer {
        edges {
          node {
            directusId
            question
            answer
          }
        }
      }
    }
  `);

  return allDirectusQuickAnswer.edges;
};
