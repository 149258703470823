import React from 'react';
import { Helmet } from 'react-helmet';
import showdown from 'showdown';

import companyDetailsHook from 'hooks/company-details/company-details.hook';
import quickAnswersHook from 'hooks/quick-answers/quick-answers.hook';

const ContactUsSnippet = (): JSX.Element => {
  const converter = new showdown.Converter();
  const company = companyDetailsHook()[0];
  const questions = quickAnswersHook();

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'ProfessionalService',
          name: 'Rapid Formations',
          image: '',
          '@id': '',
          url: 'https://www.rapidformations.co.uk/contact-us/',
          telephone: company.node.telephone_number,
          address: {
            '@type': 'PostalAddress',
            streetAddress: company.node.company_address,
            addressLocality: company.node.company_address_locality,
            postalCode: company.node.company_postcode,
            addressCountry: company.node.company_country,
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: parseFloat(company.node.company_latitude),
            longitude: parseFloat(company.node.company_longitude),
          },
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'FAQPage',
          mainEntity: [
            questions.map(({ node }) => ({
              '@type': 'Question',
              name: node.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: converter.makeHtml(node.answer),
              },
            })),
          ],
        })}
      </script>
    </Helmet>
  );
};

export default ContactUsSnippet;
