import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusCallUs, CallUsEdge } from './call-us.interface';

export default (): CallUsEdge[] => {
  const { allDirectusCallUs } = useStaticQuery<AllDirectusCallUs>(graphql`
    query {
      allDirectusCallUs(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            sort
            title
            telephone_uk
            telephone_other
            sort
          }
        }
      }
    }
  `);

  return allDirectusCallUs.edges;
};
