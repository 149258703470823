// extracted by mini-css-extract-plugin
export var quickAnswers__answer = "QuickAnswers--quickAnswers__answer--NL75K";
export var quickAnswers__arrow = "QuickAnswers--quickAnswers__arrow--RCSNV";
export var quickAnswers__arrow___down = "QuickAnswers--quickAnswers__arrow___down--lEEj1";
export var quickAnswers__arrow___up = "QuickAnswers--quickAnswers__arrow___up--hkdNj";
export var quickAnswers__header = "QuickAnswers--quickAnswers__header--znQK6";
export var quickAnswers__headerCategory = "QuickAnswers--quickAnswers__headerCategory--NQ3lr";
export var quickAnswers__question = "QuickAnswers--quickAnswers__question--Qgjxo";
export var quickAnswers__title = "QuickAnswers--quickAnswers__title--2bApN";
export var quickAnswers__trigger = "QuickAnswers--quickAnswers__trigger--gZXDx";
export var quickAnswers__wrapper = "QuickAnswers--quickAnswers__wrapper--+Ikms";
export var tkMyriadProSemiCondensed = "QuickAnswers--tk-myriad-pro-semi-condensed--q2-qs";