import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusOurLocation, OurLocationEdges } from './our-locations.interface';

export default (): OurLocationEdges[] => {
  const { allDirectusOurLocation } = useStaticQuery<AllDirectusOurLocation>(graphql`
    query {
      allDirectusOurLocation {
        edges {
          node {
            directusId
            name
            address
            slug
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid(maxHeight: 1000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            longitude
            latitude
            google_map_link
          }
        }
      }
    }
  `);

  return allDirectusOurLocation.edges;
};
