import React from 'react';

import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import callUsHook from 'hooks/call-us/call-us.hook';
import settingsHook from 'hooks/settings/settings.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import checkMobile from 'utils/mobile-check';

import { callUs, header, content, content___three, content___two } from './CallUs.scss';

export default (): JSX.Element => {
  const calls = callUsHook();
  const settings = settingsHook();
  const { node } = getFieldBySlug('call-us', sectionTitleHook());

  return (
    <>
      <div className={callUs}>
        <SectionTitle title="Contact Us" align="left" underline />

        <div role="grid">
          <div className={header} role="row">
            <div role="gridcell">Address</div>
            <div role="gridcell">Office Hours</div>
            <div role="gridcell">Phone Number</div>
          </div>

          <div className={`${content} ${content___three}`} key={node.directusId} role="row">
            <div role="gridcell">
              {settings.brand_name},<br />
              71-75, Shelton Street, <br /> Covent Garden, <br />
              London, WC2H 9JQ
            </div>
            <div role="gridcell">
              Monday to Friday <br />
              8:30am to 5:30pm
            </div>
            <div role="gridcell">
              {checkMobile() ? (
                <a href={`tel:+44${settings.telephone_number.substring(1)}`}>{settings.telephone_number}</a>
              ) : (
                `${settings.telephone_number}`
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={callUs}>
        <SectionTitle title="Useful Numbers" align="left" underline />

        <div role="grid">
          {calls.map(
            ({ node }): JSX.Element => (
              <div className={`${content} ${content___two}`} key={node.directusId} role="row">
                <div role="gridcell">{node.title}</div>
                <div role="gridcell">
                  {checkMobile() ? (
                    <a href={`tel:+44${node.telephone_uk.substring(1)}`}>{node.telephone_uk}</a>
                  ) : (
                    `${node.telephone_uk}`
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};
