import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import quickAnswerCategoriesHook from 'hooks/quick-answer-categories/quick-answer-categories.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';

import {
  quickAnswers__trigger,
  quickAnswers__title,
  quickAnswers__arrow,
  quickAnswers__arrow___down,
  quickAnswers__arrow___up,
  quickAnswers__wrapper,
  quickAnswers__header,
  quickAnswers__headerCategory,
  quickAnswers__question,
  quickAnswers__answer,
} from './QuickAnswers.scss';

const OpenHeaderHTML = (question: string): JSX.Element => (
  <div className={quickAnswers__trigger}>
    <h3 className={quickAnswers__title}>{question}</h3>
    <span className={`${quickAnswers__arrow} ${quickAnswers__arrow___down}`} />
  </div>
);

const ClosedHeaderHTML = (question: string): JSX.Element => (
  <div className={quickAnswers__trigger}>
    <h3 className={quickAnswers__title}>{question}</h3>
    <span className={`${quickAnswers__arrow} ${quickAnswers__arrow___up}`} />
  </div>
);

export default (): JSX.Element => {
  const categories = quickAnswerCategoriesHook();
  const [category, updateSelectedCategory] = useState<string>(categories[0].node.name);
  const [isCategoryOpen, setIsCategoryOpen] = useState<boolean>(false);
  const [closeAll, setCloseAll] = useState<boolean>(false);
  const [openPosition, setOpenPosition] = useState<number>(-1);
  const { node } = getFieldBySlug('quick-answers', sectionTitleHook());

  const getSelectedCategory = (selectedCategory: string) => {
    const category = categories.filter(({ node }) => node.name === selectedCategory);
    return category[0].node.questions;
  };

  const setCategory = (category: string) => {
    setIsCategoryOpen(false);
    setOpenPosition(-1);
    updateSelectedCategory(category);
  };

  const handleClick = (index: number) => {
    setCloseAll(!closeAll && index === openPosition);
    setOpenPosition(index);
  };

  return (
    <div className={quickAnswers__wrapper}>
      <SectionTitle title={node.heading} align="left" underline />
      <div className={quickAnswers__header}>
        <Collapsible
          open={isCategoryOpen}
          onOpening={() => setIsCategoryOpen(true)}
          onClosing={() => setIsCategoryOpen(false)}
          trigger={
            <div className={quickAnswers__headerCategory}>
              <span>{category}</span>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          }
          triggerWhenOpen={
            <div className={quickAnswers__headerCategory}>
              <span>{category}</span>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          }
        >
          <ul>
            {categories.map(({ node }) => (
              <li key={`category-${node.slug}`}>
                <div onClick={() => setCategory(node.name)} role="presentation">
                  {node.name}
                </div>
              </li>
            ))}
          </ul>
        </Collapsible>
      </div>

      <div>
        {getSelectedCategory(category).map((question: any, index: number) => (
          <Collapsible
            classParentString={quickAnswers__question}
            trigger={ClosedHeaderHTML(question.question)}
            triggerWhenOpen={OpenHeaderHTML(question.question)}
            key={`question-category-${category}-question-${question.directusId}`}
            open={!closeAll && openPosition === index}
            handleTriggerClick={() => handleClick(index)}
          >
            <Markdown className={quickAnswers__answer} source={question.answer} container />
          </Collapsible>
        ))}
      </div>
    </div>
  );
};
