import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import locationsHook from 'hooks/our-locations/our-locations.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';

import {
  ourOffices__wrapper,
  ourOffices__office,
  ourOffices__image,
  ourOffices__imageLondon,
  ourOffices__content,
  ourOffices__address,
  ourOffices__linkWrapper,
  ourOffices__linkMarker,
  ourOffices__link,
} from './OurOffices.scss';

export default (): JSX.Element => {
  const locations = locationsHook();
  const { node } = getFieldBySlug('our-offices', sectionTitleHook());

  return (
    <>
      <SectionTitle title={node.heading} align="left" underline />

      <div className={ourOffices__wrapper}>
        {locations.map(({ node }) => (
          <div className={ourOffices__office} key={`our-locations-${slugify(node.name)}`}>
            <div>
              <Img
                fluid={node.image.localFile.childImageSharp.fluid}
                alt={`Photograph of ${node.name} location.`}
                className={`
                    ${ourOffices__image}
                    ${node.name.toLocaleLowerCase().includes('london') ? ourOffices__imageLondon : ''}
                  `}
                fadeIn
              />
            </div>

            <div className={ourOffices__content}>
              <Markdown source={node.address} className={ourOffices__address} container />

              <div className={ourOffices__linkWrapper}>
                <FontAwesomeIcon className={ourOffices__linkMarker} icon={getSolidFontAwesomeIcon('faMapMarkerAlt')} />
                <a href={node.google_map_link} className={ourOffices__link} target="_blank" rel="noopener noreferrer">
                  View on Google maps
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
