import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CallUs from 'components/directus/call-us/CallUs';
import ContactUsForm from 'components/directus/contact-us-form/ContactUsForm';
import MainBanner from 'components/directus/main-banner/MainBanner';
import OurOffices from 'components/directus/our-offices/OurOffices';
import PageSections from 'components/directus/page-sections/PageSections';
import PageTitle from 'components/directus/page-title/PageTitle';
import QuickAnswers from 'components/directus/quick-answers/QuickAnswers';
import ContactUsRichSnippet from 'components/common/rich-snippets/contact-us/ContactUs';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

import { grid } from 'pages-styles/contact-us.scss';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'contact-us';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
      </Helmet>

      <MainBanner slug={slug} />

      <Wrapper>
        <Section bottom={0}>
          <PageTitle slug={slug} />
        </Section>
        <Section bottom={30}>
          <PageSections slug={slug} />
        </Section>
        <Section>
          <div className={grid}>
            <div>
              <QuickAnswers />
            </div>
            <div>
              <CallUs />
              <ContactUsForm />
            </div>
          </div>
        </Section>
        <Section>
          <OurOffices />
        </Section>
      </Wrapper>
      <ContactUsRichSnippet />
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "contact-us" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
