import React from 'react';

import Button from 'components/common/button/Button';
import { Props } from './FormSuccess.interface';

import { title as titleClass, subTitle as subTitleClass, form__button } from '../ContactUsForm.scss';

const FormSuccess = ({ title, subTitle, body, handleFormReset, buttonText }: Props): JSX.Element => (
  <>
    <h2 className={titleClass}>{title}</h2>
    <h3 className={subTitleClass}>{subTitle}</h3>
    <p>{body}</p>

    <Button type="button" role="secondary" onClick={handleFormReset} className={form__button}>
      {buttonText}
    </Button>
  </>
);

export default FormSuccess;
