import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import { Props } from './SectionTitle.interface';

import {
  sectionTitle,
  sectionTitle___underline,
  sectionTitle__heading,
  sectionTitle__subheading,
  sectionTitle___right,
  sectionTitle___left,
  sectionTitle___center,
} from './SectionTitle.scss';

const getAlignClass = (align: string) => {
  switch (align) {
    case 'left':
      return sectionTitle___left;
    case 'right':
      return sectionTitle___right;
    case 'center':
      return sectionTitle___center;
    default:
      return '';
  }
};

export default ({ title, subtitle, align, underline, className }: Props): JSX.Element => {
  const alignClass = getAlignClass(align);
  const underlineClass = underline ? sectionTitle___underline : '';

  return (
    <div className={`${sectionTitle} ${alignClass} ${underlineClass} ${className || ''}`}>
      <h2 className={sectionTitle__heading}>
        <Markdown source={title} />
      </h2>
      {subtitle && (
        <h4 className={sectionTitle__subheading}>
          <Markdown source={subtitle} />
        </h4>
      )}
    </div>
  );
};
