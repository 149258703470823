import React from 'react';

import contactUsForkHook from 'hooks/contact-us-form/contact-us-form.hook';
import Form from './form/Form';

export default (): JSX.Element => {
  const { node } = contactUsForkHook()[0];

  return <Form context={node} />;
};
