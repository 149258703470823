import React, { createRef, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';

import Button from 'components/common/button/Button';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { submitContactUs } from 'services';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import FormSuccess from '../form-success/FormSuccess';
import { Props, FormValues } from './Form.interface';

import {
  form__wrapper,
  form as formClass,
  form__label,
  form__input,
  form__error,
  form__button,
  form__select,
} from '../ContactUsForm.scss';

export default ({ context }: Props): JSX.Element => {
  const { node } = getFieldBySlug('contact-us-form', sectionTitleHook());
  const refReCAPTCHA = createRef<any>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const resetForm = () => setIsSubmitted(false);

  if (isSubmitted) {
    return (
      <FormSuccess
        title={context.title}
        subTitle={context.submitted_body}
        body={context.body}
        buttonText={context.submitted_button}
        handleFormReset={() => resetForm()}
      />
    );
  }

  return (
    <div className={form__wrapper}>
      <SectionTitle title={node.heading} align="left" underline />
      <p>{context.body}</p>
      <Formik
        initialValues={{ name: '', email: '', question: '', department: 'General', recaptcha: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={yup.object().shape({
          recaptcha: yup.string().required(),
          name: yup.string().required(),
          email: yup.string().required(),
          question: yup.string().required(),
          department: yup.string().required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>): Promise<void> => {
          await submitContactUs(values);
          setSubmitting(false);
          setIsSubmitted(true);
        }}
      >
        {(props) => {
          const handleBlur = (e: any) => {
            if (
              !!props.values.name &&
              !!props.values.email &&
              !!props.values.department &&
              !!props.values.question &&
              !props.values.recaptcha
            ) {
              refReCAPTCHA.current.execute();
              props.setSubmitting(true);
            }
            props.handleBlur(e);
          };

          return (
            <Form className={formClass}>
              <label htmlFor="name" className={form__label}>
                Name:
                <input
                  id="name"
                  name="name"
                  placeholder="John Doe"
                  type="text"
                  className={form__input}
                  onChange={props.handleChange}
                  onBlur={handleBlur}
                  value={props.values.name}
                  aria-invalid={props.errors.name ? 'true' : 'false'}
                  aria-describedby="nameError"
                />
                <small className={form__error} id="nameError">
                  {props.errors.name && 'Please enter your name.'}
                </small>
              </label>
              <label htmlFor="email" className={form__label}>
                Email:
                <input
                  id="email"
                  name="email"
                  placeholder="john@acme.com"
                  type="email"
                  className={form__input}
                  onChange={props.handleChange}
                  onBlur={handleBlur}
                  value={props.values.email}
                  aria-invalid={props.errors.email ? 'true' : 'false'}
                  aria-describedby="emailError"
                />
                <small className={form__error} id="emailError">
                  {props.errors.email && 'Please enter your email.'}
                </small>
              </label>
              <label htmlFor="department" className={form__label}>
                Department:
                <select
                  id="department"
                  name="department"
                  onChange={props.handleChange}
                  value={props.values.department}
                  className={form__select}
                >
                  <option value="General">General Enquiries</option>
                  <option value="ID">ID Requirements</option>
                  <option value="RenewalsCancellations">Renewals/Cancellations</option>
                  <option value="Mail">Mail</option>
                  <option value="Refunds">Refund</option>
                  <option value="VATPAYE">VAT/PAYE</option>
                  <option value="Complaint">Complaint</option>
                </select>
                <small className={form__error} id="departmentError">
                  {props.errors.department && 'Please select a Department.'}
                </small>
              </label>
              <label htmlFor="question" className={form__label}>
                Question:
                <textarea
                  id="question"
                  name="question"
                  placeholder="Enter your question"
                  rows={5}
                  className={form__input}
                  onChange={props.handleChange}
                  onBlur={handleBlur}
                  value={props.values.question}
                  aria-invalid={props.errors.question ? 'true' : 'false'}
                  aria-describedby="questionError"
                />
                <small className={form__error} id="questionError">
                  {props.errors.question && 'Please enter your question.'}
                </small>
              </label>
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY as string}
                ref={refReCAPTCHA}
                onChange={(value) => {
                  props.setFieldValue('recaptcha', value);
                  props.setSubmitting(false);
                }}
                size="invisible"
              />
              <Button type="submit" role="primary" size="large" className={form__button} disabled={props.isSubmitting}>
                {context.submit_button}
                {props.isSubmitting && (
                  <>
                    &nbsp;
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </>
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
